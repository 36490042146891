<template>
  <div>
    <v-dialog v-model="show" persistent width="800">
      <v-card>
        <v-card-title class="headline">
          Dead bird info
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-textarea
                class="mt-0"
                v-model="fields.dead_location.vmodel"
                label="Location"
                filled
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-textarea
                filled
                label="Additional info"
                v-model="fields.dead_info.vmodel"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="close" x-large>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['show', 'fields'],
  data() {
    return {}
  },
  mounted() {},

  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped></style>
