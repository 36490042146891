<template>
  <v-container>
    <DeadBirdDialog
      :fields="fields"
      :show="showDeadBirdDialog"
      @close="showDeadBirdDialog = false"
    />
    <LoggerDialog
      :fields="fields"
      @close="showLoggerDialog = false"
      :show="showLoggerDialog"
    />
    <v-row justify="center">
      <v-col cols="12">
        <v-row>
          <v-col sm="4">
            <h1>Capture</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="3" sm="6">
            <BirdInfoPanel
              :fields="fields"
              @setLocation="setLocationManually"
            />

            <v-divider class="my-4"></v-divider>
            <v-btn
              color="primary"
              block
              x-large
              @click="showLoggerDialog = true"
              >Loggers</v-btn
            >
            <v-checkbox
              :label="fields.dead.text"
              v-model="fields.dead.vmodel"
              hide-details
              class="shrink mr-2 mt-5 mb-2"
              color="red darken-3"
            ></v-checkbox>
            <v-btn
              :disabled="!fields.dead.vmodel"
              block
              large
              color="red darken-3"
              @click="showDeadBirdDialog = true"
              >Dead bird info</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <DataInputDialogRingAcrylic :data="fields.acrylic" />
            <DataInputDialogRingBto :data="fields.bto" />
            <v-divider></v-divider>
            <DateTimeInput :fields="fields" />
            <v-divider></v-divider>
            <v-textarea
              class="mt-5"
              filled
              :label="fields.comments.text"
              v-model="fields.comments.vmodel"
            ></v-textarea>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <DataInputDialog :data="fields.tarsus" :min="55" :max="75" />
            <DataInputDialog :data="fields.bill" :min="90" :max="105" />
            <DataInputDialog :data="fields.wing" :min="460" :max="520" />
            <v-row>
              <v-col cols="12" class="pt-0"
                ><DataInputDialog :data="fields.partner_bird"
              /></v-col>
              <v-divider></v-divider>
              <v-col cols="12" class="pt-0">
                <v-switch
                  class="mt-0"
                  :label="fields.blood.text"
                  v-model="fields.blood.vmodel"
                ></v-switch
              ></v-col>
            </v-row>

            <v-divider></v-divider>
            <v-radio-group v-model="fields.location.vmodel">
              <template v-slot:label>
                <div>Sub-colony</div>
              </template>
              <v-radio
                class="mb-6"
                v-for="(site, i) in campsiteLocations"
                :key="i"
                :label="site"
                :value="site"
              ></v-radio>
            </v-radio-group>
            <DataInputDialog
              :data="fields.other_location"
              v-if="fields.location.vmodel === 'Other'"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <DataInputDialog
              :data="fields.gross_weight"
              :min="2400"
              :max="4000"
            />
            <DataInputDialog :data="fields.bag_weight" :min="50" :max="200" />
            <DataInputDialog :data="fields.net_weight" />
            <DataInputDialog :data="fields.moult" />
            <v-radio-group v-model="fields.sex.vmodel" class="mt-0">
              <template v-slot:label>
                <div>Sex</div>
              </template>
              <v-radio label="Male" value="M"></v-radio>
              <v-radio label="Female" value="F"></v-radio>
            </v-radio-group>

            <v-radio-group v-model="fields.breeder.vmodel" class="mt-0">
              <template v-slot:label>
                <div>Breeding status</div>
              </template>
              <v-radio label="Breeder" :value="true"></v-radio>
              <v-radio label="Non-breeder" :value="false"></v-radio>
            </v-radio-group>
            <v-textarea
              filled
              v-show="fields.breeder.vmodel === true"
              :label="fields.nest_contents.text"
              v-model="fields.nest_contents.vmodel"
            ></v-textarea>

            <v-textarea
              filled
              v-show="fields.breeder.vmodel === false"
              :label="fields.breeder_immature_bird_age.text"
              v-model="fields.breeder_immature_bird_age.vmodel"
            ></v-textarea>
            <v-radio-group v-model="fields.capture_type.vmodel" class="mt-0">
              <template v-slot:label>
                <div>{{ fields.capture_type.text }}</div>
              </template>
              <v-radio
                label="Initial capture"
                value="Initial capture"
              ></v-radio>
              <v-radio label="Recapture" value="Recapture"></v-radio>
            </v-radio-group>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn x-large color="primary" @click="save">Save</v-btn>
              </v-col>
            </v-row> </v-col
          >´
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import localStorageUsername from '@/mixins/localstorageUsername.js'
import {
  Fields,
  pickWantedFields,
  convertFieldsToObject
} from '@/lib/Fields.js'
import Pouch from '@/store/Pouch.js'
import { EventBus } from '@/store/EventBus.js'
import DataInputDialog from '@/components/DataInputDialog.vue'
import DataInputDialogRingAcrylic from '@/components/DataInputDialogRingAcrylic.vue'
import DataInputDialogRingBto from '@/components/DataInputDialogRingBto.vue'
import LoggerDialog from '@/components/LoggerDialog.vue'
import DeadBirdDialog from '@/components/DeadBirdDialog.vue'
import DateTimeInput from '@/components/DateTimeInput.vue'
import BirdInfoPanel from '@/components/BirdInfoPanel.vue'

export default {
  name: 'Capture',
  props: {
    id: String
  },
  mixins: [localStorageUsername],
  data() {
    return {
      mode: 'new',
      edit: {
        _rev: null,
        _id: null,
        type: 'capture'
      },
      fields: Fields,
      birdInfo: null,
      snackbar: false,
      showLoggerDialog: false,
      showDeadBirdDialog: false,
      BtoError: false,
      AcryclicError: false,
      campsiteLocations: [
        'Main Colony East (MCE)',
        'Main Colony South (MCS)',
        'Above Campsite (AC)',
        'North Valley (NV)',
        'North Valley West (NVW)',
        'Other'
      ]
    }
  },
  beforeDestroy() {
    this.fields.reset()
  },
  mounted() {
    if (this.id) {
      this.mode = 'edit'
      this.findByIdAndMount(this.id)
    } else {
      this.getLocation()
    }
  },
  watch: {
    'fields.gross_weight': {
      handler: 'weightHandler',
      deep: true
    },
    'fields.bag_weight': {
      handler: 'weightHandler',
      deep: true
    }
  },

  computed: {},
  methods: {
    // fetch record by _id and populate fields
    async findByIdAndMount(id) {
      let res = await Pouch.get(id)
      this.edit._rev = res._rev
      this.edit._id = res._id
      for (const key of Object.keys(res)) {
        if (key in this.fields) {
          this.fields[key].vmodel = res[key]
        }
      }
    },
    setLocationManually() {
      // Grassholm location
      // 51.730841100569094, -5.479957028740447
      this.fields.lat.vmodel = 51.730841100569094
      this.fields.lng.vmodel = -5.479957028740447
    },
    getLocation() {
      this.$getLocation({
        enableHighAccuracy: true, //defaults to false
        timeout: Infinity, //defaults to Infinity
        maximumAge: 0
      })
        .then(coordinates => {
          console.log(coordinates)
          this.fields.lat.vmodel = coordinates.lat
          this.fields.lng.vmodel = coordinates.lng
        })
        .catch(err => {
          console.log(err)
        })
    },
    setLoggers(loggers) {
      console.log(loggers)
      this.fields.loggers.vmodel = loggers
      this.showLoggerDialog = false
    },
    weightHandler() {
      try {
        this.fields.net_weight.vmodel =
          parseFloat(this.fields.gross_weight.vmodel) -
          parseFloat(this.fields.bag_weight.vmodel)
      } catch (error) {
        console.log(error)
      }
    },

    //used when edit info
    save() {
      if (this.mode === 'new') {
        this.saveNew()
      } else if (this.mode === 'edit') {
        this.saveUpdate()
      }
    },
    async saveUpdate() {
      let doc = convertFieldsToObject(this.fields, 'capture')
      doc = { ...doc, ...this.edit }
      await Pouch.put(doc)
      EventBus.$emit('notify-user', 'Saved')
      this.fields.reset()
      this.$router.go(-1)
    },
    async saveNew() {
      // console.log(Object.keys(this.fields))
      let newDoc = {}
      newDoc.type = 'capture'
      Object.keys(this.fields).forEach(fieldname => {
        newDoc[fieldname] = this.fields[fieldname].vmodel
      })
      newDoc.scribe = this._getGlobalUsername()
      newDoc = pickWantedFields(newDoc, newDoc.type)
      await Pouch.post(newDoc)
      // EventBus.$emit('add-to-local-db')
      EventBus.$emit('notify-user', 'Saved')
      this.fields.reset()
    }
  },
  components: {
    DataInputDialog,
    DataInputDialogRingAcrylic,
    DataInputDialogRingBto,
    LoggerDialog,
    DeadBirdDialog,
    DateTimeInput,
    BirdInfoPanel
  }
}
</script>

<style lang="scss" scoped>
.white--text {
  color: #fff !important;
  font-size: 1rem;
}
</style>
