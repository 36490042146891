<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Loggers</span>
        </v-card-title>
        <v-divider class="mb-5"></v-divider>
        <v-card-text>
          <div v-for="log in fields.loggers.vmodel" :key="log.name">
            <h3 class="mb-2">{{ log.name }}</h3>
            <v-select
              filled
              :label="log.name + ' type'"
              :items="loggerType"
              v-model="log.type"
            ></v-select>
            <v-text-field
              v-model="log.id"
              filled
              :label="log.name + ' ID'"
              style="width: 50%;margin-bottom:-30px"
            ></v-text-field>
            <v-select
              class="mt-5"
              filled
              :label="log.name + ' status'"
              :items="loggerStatus"
              v-model="log.status"
            ></v-select>
            <v-textarea
              class="mt-5"
              rows="4"
              no-resize
              auto-grow
              filled
              :label="log.name + ' data'"
              v-model="log.data"
            ></v-textarea>
            <v-divider class="mb-5"></v-divider>
          </div>
          <v-btn color="primary" block outlined x-large @click="add"
            >Add logger...</v-btn
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" x-large text @click="close">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
/* eslint-disable no-unused-vars */

export default {
  name: 'Loggers',
  props: ['show', 'fields'],
  data() {
    return {
      loggerType: ['GPS', 'GLS', 'Camera', 'TDR', 'Accelerometer'],
      loggerStatus: ['deployed', 'removed', 'lost']
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    add() {
      let currentIndex
      // Sometimes the vmodel on loggers is null not an empty array
      if (!this.fields.loggers.vmodel) {
        this.fields.loggers.vmodel = []
      }
      if (this.fields.loggers.vmodel.length === 0) {
        currentIndex = -1
      } else {
        currentIndex = this.fields.loggers.vmodel[
          this.fields.loggers.vmodel.length - 1
        ].key
      }
      this.fields.loggers.vmodel.push({
        key: currentIndex + 1,
        name: 'Logger ' + (currentIndex + 2).toString(),
        type: null,
        id: null,
        status: null,
        data: null
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
